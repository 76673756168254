{
  "locale": "en-US",
  "translations": {
    "NeedHelp?": "Need help?",
    "TermOfUse?": "Term of use?",
    "Message.ConsumerKeyValidUntil": "The Consumer Key Valid Until date is later than the current date.",
    "Message.EncryptionCertificateValidUntil": "The Encryption Certificate Valid Until date is later than the current date.",
    "Message.OverSizeFileUpload": "The uploaded file exceeds the allowed size. Please check again.",
    "Message.SigningKeyValidUntil": "The Signing Key Valid Until date is later than the current date.",
    "1827245616056417685": "IP Address",
    "2107698215875362705": "Merchant Name",
    "257179844007207698": "AccountType",
    "2774600826494125537": "Billing Number",
    "2826581353496868063": "Language",
    "2981298559816708937": "Transaction Status",
    "3001605268665726144": "CRes",
    "3500863025421875208": "Transaction ID",
    "4213763744279134268": "Expire Date",
    "4327471061205783634": "Currency",
    "4348241696709904778": "AcquirerCountry",
    "4407559560004943843": "Last Name",
    "4787864116367551656": "AReq",
    "5163600345654321396": "BU",
    "5228689756606215840": "RReq",
    "5228752985586854237": "MerchantCountry",
    "5528918281864081161": "RRes",
    "6028371114637047813": "First Name",
    "6085046065132163998": "Message Body",
    "6424164076261744306": "Account Number",
    "6533918067030990396": "Amount",
    "7091405256925838057": "MerchantCategoryCode",
    "727839883615096751": "ARes",
    "8479942310290871374": "CReq",
    "9080610513887083246": "Acquirer BIN",
    "9179303794431488464": "Message Header",
    "AccountClosed": "Account Closed",
    "AccountNumber": "Account Number",
    "AccountPan": "Account Number/Pan",
    "AccountType": "Account Type",
    "AcquirerBIN": "Acquirer BIN",
    "AcquirerCountry": "Acquirer Country",
    "Action": "Action",
    "ActionDateTime": "Action Time",
    "ActionName": "Action Name",
    "ActionType": "Action Type",
    "ActionUser": "Action User",
    "ActionUserID": "Action User",
    "ActivateAt": "Activate At",
    "Active:Hoạt động": "Active",
    "Active": "Active",
    "Add:Thêm mới": "Add New",
    "Add": "Add",
    "AddGlobalScreenConfigurations": "Add Global Screen Configurations",
    "AddNew": "Add New",
    "AddressLine1": "Address Line 1 ",
    "AddressLine2": "Address Line 2 ",
    "AddRule": "Add Rule",
    "Administration": "Administration",
    "All": "All",
    "Amount": "Amount",
    "Apply": "Apply",
    "Back": "Back",
    "Before": "Before",
    "BID": "BID",
    "BillingNumber": "Billing Number",
    "BIN": "BIN",
    "BINID": "BIN ID",
    "BinRange": "BIN-Range",
    "BINRANGE": "BIN-Range",
    "BINRangeConfigured": "BIN-Range Configured",
    "BU": "BU",
    "BUCode": "BU Code",
    "BuCode": "Business Unit Code",
    "BUIntegration": "BU Integration",
    "BUName": "BU Name",
    "BuName": "Business Unit Name",
    "BusinessUnitAdd": "Add Business Unit",
    "BusinessUnitDisabled": " Business Unit Disabled.",
    "BusinessUnitInfo": "Business Unit Information",
    "BusinessUnitManagement": "Business Unit Management",
    "BUTestPage": "Business Unit Test Page",
    "Cache": "Cache",
    "CacheDetails": "Cache Details",
    "CacheKey": "Key Cache",
    "CacheManagement": "Cache Management",
    "CacheType": "Cache Type",
    "Cancel": "Cancel",
    "CardProgram": "Card Program",
    "CardProgramAdd": "Card Program Add",
    "CardProgramDetail": "Card Program Detail",
    "CardProgramDuplicate": "Duplicate BIN Range.",
    "CardProgramExist": "Existed BIN Range.",
    "CardProgramInvalidBU": "BU does not exist or is disabled.",
    "CardProgramInvalidService": "Service does not exist or is disabled.",
    "CardProgramMinBiggerMax": "Start is not bigger End.",
    "CardProgramSearch": "Card Program Management",
    "Category": "Category",
    "CategoryManagement": "Category Management",
    "Challenge": "Challenge",
    "ChangePassword": "Change Password",
    "CloneRule": "Clone Rule",
    "CloneRuleFrom": " Clone Rule From ",
    "Comment": "Comment",
    "ConfigBinRange": "Config BIN-Range",
    "Confirm": "Confirm",
    "ConfirmConsumerInfoBeforeActivation": "Please confirm the consumer information before activating token",
    "ConfirmMastercardRequest": "It may take a couple of time to request info from Mastercard, are you sure to continue ?",
    "ConfirmNewPassword": "Confirmation password",
    "ConfirmPasswordMismatch": "Confirmation password does not match",
    "ConfirmRenew": "Token will be renewed following the Mastercard regulation, Are you sure to continue ?",
    "ConfirmUnsuspendToken": "Are you sure to unsuspend the token ?",
    "ConsumerId": "Consumer Id",
    "ConsumerKey": "Consumer Key",
    "CreatedAt": "Created At",
    "CreatedBy": "Created By",
    "CreatePolicyDraft": "Create Policy Draft",
    "Credit": "Credit",
    "Current: Hiện tại": "Current",
    "Current": "Current",
    "DateCreated": "Date Created",
    "DateFormat": "Date Format",
    "DateTime": "Date and Time",
    "DateTimeModify": "Date Time Modify",
    "DateTimePublished": "DateTimePublished",
    "Debit": "Debit",
    "Default": "Default",
    "Delete": "Delete",
    "DeleteConfirm": "Confirmation delete",
    "Description": "Description",
    "Description[5]": "Description [5]",
    "Detail": "Detail",
    "Details": "Details",
    "DeviceLost": "Device Lost",
    "DeviceSerialNumber": "Device Serial Number",
    "DeviceStolen": "Device Stolen",
    "DeviceType": "DeviceType",
    "Disable:Vô hiệu": "Disable",
    "Disable": "Disable",
    "Disabled": "Disabled",
    "DisplayName:Tên hiển thị": "Display Name",
    "DisplayName": "Display Name",
    "DisplayNeedHelpLabel": "Display Need help Label",
    "DisplayTermOfUseLabel": "Display Term of use Label",
    "DuplicationRequest": "Data already exists, please check again.",
    "Email": "Email",
    "Email1": "Email 1",
    "Email2": "Email 2",
    "EmptyTableMessage": "No data found",
    "Enable:Hoạt động": "Enable",
    "Enable": "Enable",
    "EncryptionCertificateFile": "Encryption Certificate File",
    "EncryptionCertificateFingerprint": "Encryption Certificate Fingerprint",
    "End": "End",
    "EnterNewPassword": "Enter New Password",
    "EnterOldPassword": "Enter Old Password",
    "Environment": "Environment",
    "ErrorMessage": "Error Message",
    "ErrorMessage[21]": "Error Message [21]",
    "ExpireDate": "Expire Date",
    "ExpiredPasswordTitle": "Your password is expired on {$INTERPOLATION}, please update your password to continue using ATG Digitals Portal.",
    "ExpirePasswordDateTime": "Password expiration date and time: {$INTERPOLATION}",
    "ExportCsv": "Export Csv",
    "ExportExcel": "Export excel",
    "ExportZip": "Export Zip",
    "Fail with Feedback": "Fail with Feedback",
    "Fail": "Fail",
    "FirstName": "First Name",
    "ForgotPassword": "Forgot password",
    "ForgotPasswordMessage": "We have sent a password reset email to {$START_TAG_SPAN}{$INTERPOLATION}{$CLOSE_TAG_SPAN}. Please follow the instructions to complete the password reset process.",
    "ForgotPasswordNotification": "If you forget your password, we will send you an email with a password reset link. Please click on that link and follow the instructions to reset your password.",
    "GeneralInformation": "General Information",
    "GenerateKey": "Generate Key",
    "GlobalScreen": "Global Screen",
    "GlobalScreenConfigurations": "Global Screen Configurations",
    "GlobalScreenLogDetail": "Global Screen Log Detail",
    "IncorrectCode": "Incorrect Code",
    "IncorrectCode[15]": "Incorrect Code [15]",
    "IncorrectCode[15]/LeftBlankCode[16]": "Incorrect code [15]/Left Blank code [16]",
    "Infomation": "Infomation",
    "InternalServerError": "Internal Server Error",
    "InvalidNewPassword": "New password cannot match the lastest 5 passwords, please check again",
    "InvalidOldPassword": "The old password is invalid",
    "InvalidPhoneNumber": "Invalid phone number",
    "InvalidURL": "Invalid URL",
    "IPAddress": "IP Address",
    "ItemName": "Item Name",
    "LabelNeedHelp": "Label Need help",
    "LabelNeedHelp[8]": "Label Need help [8]",
    "LabelSubmit": "Label Submit",
    "LabelSubmit[14]": "Label Submit [14]",
    "LabelSubmit[6]": "Label Submit [6]",
    "LabelTermOfUse": "Label Term of use",
    "LabelTermOfUse[9]": "Label Term of use [9]",
    "Language": "Language",
    "LanguageType": "Language Type",
    "LastEditPasswordDateTime": "Last password reset time: {$INTERPOLATION}",
    "LastimeModified": "Lastime Modified",
    "LastLoginDate:Truy cập gần đây": "Last Login Date",
    "LastLoginDate": "Last Login Date",
    "LastName": "Last Name",
    "LastStatusUpdatedAt": "Last Status Updated At",
    "LeftBlankCode": "Left Blank Code",
    "LeftBlankCode[16]": "Left Blank Code [16]",
    "ListRule": "LIST RULES",
    "ListRuleAddEdit": "LIST RULES (Add and Edit Rules)",
    "Locked": "Locked",
    "LogAction": "Log Action",
    "LogDetail": "Log Detail",
    "LogDetailChanges": "LOG DETAIL (Changes)",
    "LogDetailInformation": "Log Detail Information",
    "LogInformation": "Log Information",
    "MasterCardTokenDetail": "MasterCard Token Detail",
    "MCToken": "MC Token",
    "MerchantCategoryCode": "Merchant Category Code",
    "MerchantCountry": "Merchant Country",
    "MerchantName": "Merchant Name",
    "Message.AccessDenied": "You do not have access to view this page within the site.",
    "Message.ActivateAccountConfirm": "Are you sure you want to activate your account?",
    "Message.BINNoExists": "BIN no exists",
    "Message.BinRangeDisabled": "There's a BIN Range recently disabled, please try again",
    "Message.BUCodeExist": "BU Code already exists!",
    "Message.BUDeclaredService": "BU has been declared service",
    "Message.BUDisabled": "BU is disabled, please try again",
    "Message.ChangePasswordSuccess": "Your password has been reset succcessfully.",
    "Message.CloneFail": "Clone Failed",
    "Message.CloneSuccess": "Clone Successfully",
    "Message.ComebackLater": "Please try later",
    "Message.ConfirmGenerateKey": "Do you want to create a new key?",
    "Message.ConsumerKey": "Consumer Key",
    "Message.ContactAdminstrator": "Please try again or contact the system Administrator.",
    "Message.CreatePolicyDraftSuccess": "Create Policy Draft Successfully",
    "Message.DataNotFound": "No data found.",
    "Message.DisableAccountConfirm": "Are you sure you want to disable your account??",
    "Message.Error403": "You don't have permission!",
    "Message.Failed": "Failed",
    "Message.FailureAction": "Update Failed",
    "Message.GenerateKey": "Generate Key",
    "Message.GenerateKeySuccess": "Generate Key Successfully",
    "Message.InsertSuccess": "Insert Successfully.",
    "Message.IntegrationAuthenticationKey": "Integration Authentication Key",
    "Message.InternalServerError": "Internal server error.",
    "Message.Invalid": " {$PH} Invalid : {$PH_1}",
    "Message.InvalidAction": "Invalid Action.",
    "Message.InvalidCardBINLength": "BIN range consists of 6 or 8 characters",
    "Message.InvalidDate": "Invalid Date",
    "Message.InvalidEmail": "Invalid email format",
    "Message.InvalidMax": "Maximum value is {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Max length {$INTERPOLATION} characters",
    "Message.InvalidMin": "Minimum value is {$INTERPOLATION}",
    "Message.InvalidMinLength": "Must contain at least {$INTERPOLATION} characters",
    "Message.InvalidMinMaxBINLength": "Invalid length",
    "Message.InvalidMonth": "Invalid Month",
    "Message.InvalidPhoneNumber": "Must enter 10 numeric characters and the first character must start with 0.",
    "Message.InvalidRequest": "Invalid Request",
    "Message.InvalidSession": "Your session has been expired. Please login again.",
    "Message.InvalidToken": "Invalid Token",
    "Message.KeysInformation": "BU Key",
    "Message.MaxDate": "The selected date must be less than",
    "Message.MIDNotExist": "MID not exist",
    "Message.MinDate": "The selected date must be greater than",
    "Message.NewPasswordMatchOldPassword": "The new password must not be the same as the old password, please check again.",
    "Message.No": "No",
    "Message.NotFoundText": "Not found text",
    "Message.NoWhitespace": "Can not input blank",
    "Message.PasswordPolicyNotMatch": "The reset password is not in correct format, please re-enter.",
    "Message.PhoneNumberExist": "Phone number already exists!",
    "Message.PleaseSelectBU": "Please Select BU before Add Rule",
    "Message.PleaseSelectFileEncryptionCertificateFile": "Please select the Encryption Certificate File.",
    "Message.PleaseSelectFileSigningKeyFile": "Please select the Signing Key File.",
    "Message.PublishPolicyDraftSuccess": "Publish Policy Draft Successfully",
    "Message.PublishPolicySuccess": "Publish Policy Successfully",
    "Message.ReportCriteriaInformation": "Reporting criteria",
    "Message.RequestChangePasswordSuccess": "Password reset instructions have been sent to your email.",
    "Message.required": "Field is required",
    "Message.RequiredFail": "Please fill in required fields",
    "Message.RoleInformation": "Role Information",
    "Message.SelectAtLeastOneAuthenticationMethod": "Select at least one authentication method",
    "Message.SigningKey": "Signing Key",
    "Message.SuccessAction": "Action Successfully.",
    "Message.TabCardProgram": "Card Program Information",
    "Message.TheBUHasBeenConfiguredWithAllLanguageTypes": "\n          The Business Unit has been configured with all language types, please check again.",
    "Message.TokenExpiration": "Token has been expired",
    "Message.UnderMaintenance": "Maintenance",
    "Message.UpdateFail": "Failed",
    "Message.UpdatePasswordSuccess": "Successfully updated the new password",
    "Message.UpdateSuccess": "Update Successfully",
    "Message.ValidConfiguration": "Valid configuration",
    "Message.Yes": "Yes",
    "MessageBody": "Message Body",
    "MessageHeader": "Message Header",
    "Methods": "Methods",
    "ModifyDateTime": "Modify Date Time",
    "Name": "Name",
    "NewPassword": "New Password",
    "NewValue": "New Value",
    "Next": "Next",
    "ngb.alert.close": "Close",
    "ngb.carousel.next": "Next",
    "ngb.carousel.previous": "Previous",
    "ngb.carousel.slide-number": " Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.datepicker.next-month": "Next month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.select-year": "Select year",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.first": "««",
    "ngb.pagination.last-aria": "Last",
    "ngb.pagination.last": "»»",
    "ngb.pagination.next-aria": "Next",
    "ngb.pagination.next": "»",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.pagination.previous": "«",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.HH": "HH",
    "ngb.timepicker.hours": "Hours",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.timepicker.SS": "SS",
    "ngb.toast.close-aria": "Close",
    "NotApplicable": "Not Applicable",
    "Notice": "Notice: ",
    "OK": "OK",
    "OldPassword": "Old password",
    "OldValue": "Old Value",
    "OOB": "OOB",
    "OrderNumber": "Order Number",
    "OSName": "OSName",
    "Other": "Other",
    "OTPEmail": "OTP Email",
    "OTPEMail": "OTP Email",
    "OTPEOTT": "OTP OTT",
    "OTPOOB": "OTP OOB",
    "OTPOTT": "OTP OTT",
    "OTPSMS": "OTP SMS",
    "Outcome": "Outcome",
    "Page": "Page",
    "PANLength": "PAN Length",
    "Password": "Password",
    "PasswordChangeNotice": "Password must contains 8-64 characters, including at least 1 number, 1 letter, 1 special character and must not be the same as the 5 most recent passwords.",
    "PendingChange": "Pending Change",
    "PermissionLog": "History permission",
    "PhoneNumber: Số điện thoại": "Phone Number",
    "PhoneNumber:Số điện thoại": "Phone Number",
    "PhoneNumber": "Phone Number",
    "PleaseSelectCacheType": "Please select cache type",
    "Policy": "Policy",
    "PolicyAdd": "Policy Add",
    "PolicyCurrent": "Policy Current",
    "PolicyEditor": "Policy Editor",
    "PolicyID": "Policy ID",
    "PolicyInformation": "Policy Information",
    "PolicyLogAction": "Policy Log Action",
    "PolicyLogDetail": "Policy Log Detail",
    "PolicyName": "Policy Name",
    "PolicyPublishedAndBINRangeConfiguration": "Policy Published & BIN-Range Configuration",
    "PolicySearch": "Policy Management",
    "PolicyUnsaveSelectedRule": "New Rules have been selected, do you want to exit without save?",
    "Previous": "Previous",
    "Production": "Production",
    "Profile": "Profile",
    "PublicKey": "Public Key",
    "PublishAndConfig": "Publish And Config",
    "PublishDateTime": "Publish Date Time",
    "PublishPolicyConfirmination": "Are you sure want to apply change?",
    "PublishPolicyDraft": "Publish Policy Draft",
    "PublishPolicyOverrideConfirmation": "Data has been modified before, this action will affect the old data. Are you sure want to continue?",
    "PushProvisioning": "Push Provisioning",
    "PushProvisioningAdd": "Push Provisioning Add",
    "PushProvisioningDetail": "Push Provisioning Detail",
    "PushProvisioningInformation": "Push Provisioning Information",
    "PushProvisioningManagement": "Push Provisioning Management",
    "PushProvisioningRegister": "Push Provisioning Register",
    "PushProvisioningSearch": "Push Provisioning Management",
    "PwdRuleChar": "At least 1 character",
    "PwdRuleLength8And64": "From 8 to 64 characters",
    "PwdRuleNum": "At least 1 numeric character.",
    "PwdRuleSpec": "At least 1 special character. Example: !@#$%^*()”",
    "ReasonCode": "ReasonCode",
    "Rejected": "Rejected ",
    "ReloadAll": "Reload all",
    "ReloadCache": "Reload cache",
    "Remark: Ghi chú": "Remark",
    "Remark:Ghi chú": "Remark",
    "Remark": "Remark",
    "Remove": "Remove",
    "RemoveCache": "Delete cache",
    "RemovePolicyDraftConfirmination": "Do you want to continue? This action will delete this current Policy's draft",
    "Report": "Report",
    "ReportCriteria": "Report Criteria",
    "ReportGroup": "Report Group",
    "RequestChangePassword": "Send request to reset password.",
    "ReturnHomePage": "Return Homepage",
    "ReturnToLoginPage": "Return to login",
    "ReviewRule": "Review Rule",
    "Role": "Role",
    "RowPerPage": "Rows",
    "Rule": "Rule",
    "RuleDescription": "Rule Description",
    "RuleID": "Rule ID",
    "RuleLog": "Rule Log",
    "RuleLogAction": "Rule Log Action",
    "RuleName": "Rule Name",
    "RulesEditor": "Rules Editor",
    "RulesManagement": "Rules Management",
    "RulesSearch": "Rules Search",
    "RuleStatement": "Rule Statement",
    "Save": "Save",
    "Search": "Search",
    "SendRequest": "Send Request",
    "Service": "Service",
    "ServiceInformation": "Service Information",
    "ServiceName": "ServiceName",
    "SetPasswordTitle": "Set Password",
    "SignIn": "Log In",
    "SigningKeyAlias": "Signing Key Alias",
    "SigningKeyFile": "Signing Key File",
    "SigningKeyPassword": "Signing Key Password",
    "SignInTitle": "LOG IN",
    "SignOut": "Log Out",
    "Start": "Start",
    "Status: Trạng thái": "Status",
    "Status": "Status",
    "StatusPublish": "Status Publish",
    "Submit": "Submit",
    "Success": "Success",
    "SuspectedFraud": "Suspected Fraud",
    "System": "System",
    "Tab.AdditionalFields": "Additional Fields",
    "Tab.BillingInfomation": "Billing Infomation",
    "Tab.BusinessUnitInfomation": "Business Unit Infomation",
    "Tab.ErrorMessageInformation": "Error Message Information",
    "Tab.FailWithFeedbackInformation": "Fail With Feedback Information",
    "Tab.LogDetailInfomation": "Log Detail Infomation",
    "Tab.RuleInfomation": "Rule Infomation",
    "Tab.RuleStatememt": "Rule Statememt",
    "Tab.StepUpInformation": " Step-up Information",
    "Tab.SystemInformation": "System Information",
    "Tab.TokenInfomation": "Token details",
    "TestConfiguration": "Test Configuration",
    "Title": "Title",
    "Title[11]": "Title [11]",
    "Title[17]": "Title [17]",
    "Title[4]": "Title [4]",
    "TokenHistory": "Token History",
    "TokenManagement": "Token Management",
    "TokenManagementSearch": "Token Management Search",
    "TokenType": "Token Type",
    "TotalCaches": "Total caches",
    "TransactionDetail[12]": "Transaction detail [12]",
    "TransactionDetail[18]": "Transaction detail [18]",
    "TransactionResult": "Transaction Result",
    "TUR": "TUR",
    "UAT": "UAT",
    "Up/Down": "Up/Down",
    "Update:Cập nhật": "Update",
    "Update": "Update",
    "UpdateAccountConfirm": "Are you sure you want to update your account information??",
    "UpdatedAt": " Date Time Update",
    "UpdatedBy": "User Update",
    "URL": "URL",
    "UrlLogo": "Url Logo ",
    "URLlogo": "URL logo",
    "URLNeedHelp": "URL Need help",
    "URLTermOfUse": "URL Term of use",
    "User Modify": "User Modify",
    "User": "User",
    "UserAdd": "Add User",
    "UserID:Số ID của người dùng": "User ID",
    "UserID": "User ID",
    "UserIDModify": "User modify",
    "UserInformation": "User Information",
    "UserManagement": "User Management",
    "UserModify": "User Modify",
    "UserName": "UserName",
    "UsernameUpdate": "User Updated",
    "UserPublish": "User Publish",
    "UserPublished": "User Published",
    "UserSearch": "User Search",
    "ValidateLabel": "Validate Label",
    "ValidateLabel[19]": "Validate Label [19]",
    "ValidateLink": "Validate Link",
    "ValidateLink[20]": "Validate Link [20]",
    "ValidationScreenOTPEmail": "Validation screen OTP Email",
    "ValidationScreenOTPOOB": "Validation screen OTP OOB",
    "ValidationScreenOTPOTT": "Validation screen OTP OTT",
    "ValidationScreenOTPSMS": "Validation screen OTP SMS",
    "Value": "Value",
    "VerificationCode": "Verification Code",
    "VerificationCode[13]": "Verification Code [13]",
    "View": "View",
    "ViewDetailMasterCard": " View Detail Master Card ",
    "ViewPolicyCurrent": "View policy Current",
    "ViewPolicyDraft": "View version Draft",
    "VisibleAdminOnly": "Show with administrators only",
    "Message.InsertFail": "Insert failed",
    "ZZ": "EndOfTranslations"
  }
}
