import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { provideRouter } from '@angular/router';

import en from '@locale/messages.en.json';
import vi from '@locale/messages.vi.json';
import { routes } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { defaultLanguage } from './utils/constatnts';
import { provideAnimations } from '@angular/platform-browser/animations';

export function initializeApp() {
  const language: string = defaultLanguage;
  loadTranslations(language === 'en' ? en.translations : vi.translations);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService] },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: APP_INITIALIZER, useFactory: initializeApp },
  ]
};
