{
  "locale": "vi",
  "translations": {
    "ngb.alert.close": "Close",
    "ngb.carousel.next": "Next",
    "ngb.carousel.previous": "Previous",
    "ngb.carousel.slide-number": " Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.datepicker.next-month": "Next month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.select-year": "Select year",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.first": "««",
    "ngb.pagination.last-aria": "Last",
    "ngb.pagination.last": "»»",
    "ngb.pagination.next-aria": "Next",
    "ngb.pagination.next": "»",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.pagination.previous": "«",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.HH": "HH",
    "ngb.timepicker.hours": "Hours",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.timepicker.MM": "MM",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.timepicker.SS": "SS",
    "ngb.toast.close-aria": "Close",
    "AccountClosed": "Account closed",
    "AccountNumber": "Account Number",
    "AccountPan": "Account Number/Pan",
    "AccountType": "Account Type",
    "AcquirerBIN": "Acquirer BIN",
    "AcquirerCountry": "Acquirer Country",
    "Action": "Thao tác",
    "ActionDateTime": "Ngày thực hiện",
    "ActionName": "Tên hành động",
    "ActionSend":"Gửi mail",
    "ActionTest":"Gửi mail test",
    "ActionType": "Action Type",
    "ActionUser": "Action User",
    "ActionUserID": "Người thực hiện",
    "ActivateAt": "Activate At",
    "Active:Hoạt động": "Hoạt động",
    "Active": "Hoạt động",
    "Add:Thêm mới": "Thêm mới",
    "Add": "Thêm mới",
    "AddGlobalScreenConfigurations": "Add Global Screen Configurations",
    "AddNew": "Add New",
    "AddressLine1": "Address Line 1 ",
    "AddressLine2": "Address Line 2 ",
    "AddRule": "Thêm Rule",
    "Administration": "Quản trị hệ thống",
    "All": "Tất cả",
    "Amount": "Amount",
    "Apply": "Áp dụng",
    "Back": "Quay lại",
    "Before": "Trước đây",
    "BID": "BID",
    "BillingNumber": "Billing Number",
    "BIN": "BIN",
    "BINID": "BIN ID",
    "BinRange": "Bin-range",
    "BINRANGE": "BIN-Range",
    "BINRangeConfigured": "BIN-Range Configured",
    "BU": "BU",
    "BUCode": "BU code ",
    "BuCode": "Business Unit Code",
    "BUIntegration": "BU Integration",
    "BUName": "BU name ",
    "BuName": "Business Unit Name",
    "BusinessUnitAdd": "Thêm mới Business Unit",
    "BusinessUnitDisabled": " Business Unit Disabled.",
    "BusinessUnitInfo": "Thông tin Business Unit",
    "BusinessUnitManagement": "Quản lý Business Unit",
    "BUTestPage": "Business Unit Test Page",
    "Cache": "Cache",
    "CacheDetails": "Chi tiết cache",
    "CacheKey": "Khóa cache",
    "CacheManagement": "Quản lý cache",
    "CacheType": "Loại cache",
    "Cancel": "Hủy",
    "CardProgram": "Card Program",
    "CardProgramAdd": "Card Program Add",
    "CardProgramDetail": "Card Program Detail",
    "CardProgramDuplicate": " Duplicate BIN Range.",
    "CardProgramExist": " Existed BIN Range.",
    "CardProgramInvalidBU": " BU does not exist or is disabled.",
    "CardProgramInvalidService": " Service does not exist or is disabled.",
    "CardProgramMinBiggerMax": " Start is not bigger End.",
    "CardProgramSearch": "Card Program Management",
    "Category": "Danh mục",
    "CategoryManagement": "Cấu hình",
    "Challenge": "Challenge",
    "ChangePassword": "Đổi mật khẩu",
    "CloneRule": "Clone Rule",
    "CloneRuleFrom": " Clone Rule From ",
    "Comment": "Comment",
    "ConfigBinRange": "Config Bin-Range",
    "Confirm": "Xác nhận",
    "ConfirmConsumerInfoBeforeActivation": "Please confirm the consumer information before activating token",
    "ConfirmMastercardRequest": "It may take a couple of time to request info from Mastercard, are you sure to continue ?",
    "ConfirmNewPassword": "Xác nhận mật khẩu",
    "ConfirmPasswordMismatch": "Confirmation password does not match",
    "ConfirmRenew": "Token will be renewed following the Mastercard regulation, Are you sure to continue ?",
    "ConfirmUnsuspendToken": "Are you sure to unsuspend the token ?",
    "ConsumerId": "Consumer Id",
    "ConsumerKey": "Consumer Key",
    "CreatedAt": "Created At",
    "CreatedBy": "Created By",
    "CreatePolicyDraft": "Tạo Policy Draft",
    "Credit": "Credit",
    "Current: Hiện tại": "Hiện tại ",
    "Current": "Hiện tại",
    "DateCreated": "Ngày tạo",
    "DateFormat": "Date Format",
    "DateTime": "Date and Time",
    "DateTimeModify": "Ngày giờ cập nhật",
    "DateTimePublished": "DateTimePublished",
    "Debit": "Debit",
    "Default": "Default",
    "Delete": "Xóa",
    "DeleteConfirm": "",
    "Description": "Mô tả",
    "Description[5]": "Description [5]",
    "Detail": "Chi tiết",
    "Details": "Chi tiết",
    "DeviceLost": "Device Lost",
    "DeviceSerialNumber": "Device Serial Number",
    "DeviceStolen": "Device Stolen",
    "DeviceType": "DeviceType",
    "Disable:Vô hiệu": "Vô hiệu",
    "Disable": "Vô hiệu",
    "Disabled": "Vô hiệu",
    "DisplayName:Tên hiển thị": "Tên hiển thị",
    "DisplayName": "Tên hiển thị",
    "DisplayNeedHelpLabel": "Display Need help Label",
    "DisplayTermOfUseLabel": "Display Term of use Label",
    "DuplicationRequest": " Data already exists, please check again.",
    "Email": "Email",
    "Email1": "Email 1",
    "Email2": "Email 2",
    "EmptyTableMessage": "Không tìm thấy kết quả phù hợp",
    "Enable:Hoạt động": "Hoạt động",
    "Enable": "Enable",
    "EncryptionCertificateFile": "Encryption Certificate File",
    "EncryptionCertificateFingerprint": "Encryption Certificate Fingerprint",
    "End": "End",
    "EnterNewPassword": "Nhập mật khẩu mới",
    "EnterOldPassword": "Enter Old Password",
    "Environment": "Môi trường",
    "ErrorMessage": "Error Message",
    "ErrorMessage[21]": "Error Message [21]",
    "ExpireDate": "Expire Date",
    "ExpiredPasswordTitle": "Mật khẩu đã hết hạn vào {$INTERPOLATION}, bạn vui lòng cập nhật mật khẩu để tiếp tục sử dụng cho ATG Digitals Portal.",
    "ExpirePasswordDateTime": "Thời gian mật khẩu hết hạn: {$INTERPOLATION}",
    "ExportCsv": "Xuất Csv",
    "ExportExcel": "Xuất excel",
    "ExportZip": "Xuất Zip",
    "Fail with Feedback": "Fail with Feedback",
    "Fail": "Fail",
    "FirstName": "First Name",
    "ForgotPassword": "Quên mật khẩu",
    "ForgotPasswordMessage": " Chúng tôi đã gửi email đặt lại mật khẩu đến {$START_TAG_SPAN}{$INTERPOLATION}{$CLOSE_TAG_SPAN}. Vui lòng thực hiện theo hướng dẫn để hoàn tất quy trình cập nhật mật khẩu ",
    "ForgotPasswordNotification": " Nếu bạn quên mật khẩu, chúng tôi sẽ gửi một email cho bạn với liên kết đặt lại mật khẩu. Bạn vui lòng nhấn vào liên kết đó và làm theo hướng dẫn để tạo một mật khẩu mới ",
    "GeneralInformation": "General Information",
    "GenerateKey": "Generate Key",
    "GlobalScreen": "Global Screen",
    "GlobalScreenConfigurations": "Global Screen Configurations",
    "GlobalScreenLogDetail": "Global Screen Log Detail",
    "IncorrectCode": "Incorrect Code",
    "IncorrectCode[15]": "Incorrect Code [15]",
    "IncorrectCode[15]/LeftBlankCode[16]": "Incorrect code [15]/Left Blank code [16]",
    "Infomation": "Infomation",
    "InternalServerError": "Lỗi hệ thống",
    "InvalidNewPassword": "Mật khẩu mới không được trùng với 5 mật khẩu gần nhất, vui lòng kiểm tra lại",
    "InvalidOldPassword": "The old password is invalid",
    "InvalidPhoneNumber": "Invalid phone number",
    "InvalidURL": "Invalid URL",
    "IPAddress": "IP Address",
    "ItemName": "Thông tin",
    "LabelNeedHelp": "Label Need help",
    "LabelNeedHelp[8]": "Label Need help [8]",
    "LabelSubmit": "Label Submit",
    "LabelSubmit[14]": "Label Submit [14]",
    "LabelSubmit[6]": "Label Submit [6]",
    "LabelTermOfUse": "Label Term of use",
    "LabelTermOfUse[9]": "Label Term of use [9]",
    "Language": "Language",
    "LanguageType": "Language Type",
    "LastEditPasswordDateTime": "Thời gian đổi mật khẩu gần nhất: {$INTERPOLATION}",
    "LastimeModified": "Lastime Modified",
    "LastLoginDate:Truy cập gần đây": "Truy cập gần đây",
    "LastLoginDate": "Truy cập gần đây",
    "LastName": "Last Name",
    "LastStatusUpdatedAt": "Last Status Updated At",
    "LatestDateTimeSent":"Lần gửi gần nhất",
    "LatestEmailReceived":"Latest email received",
    "LatestUserSent":"Người gửi gần nhất",
    "LeftBlankCode": "Left Blank Code",
    "LeftBlankCode[16]": "Left Blank Code [16]",
    "ListRule": "Danh sách Rules",
    "ListRuleAddEdit": "Danh sách Rules (Tạo hoặc chỉnh sửa)",
    "Locked": "Khóa",
    "LogAction": "Lịch sử thao tác",
    "LogDetail": "Chi tiết Log",
    "LogDetailChanges": "LOG DETAIL (Changes)",
    "LogDetailInformation": "Log Detail Information",
    "LogInformation": "Thông tin lịch sử",
    "MasterCardTokenDetail": "MasterCard Token Detail",
    "MCToken": "MC Token",
    "MerchantCategoryCode": "Merchant Category Code",
    "MerchantCountry": "Merchant Country",
    "MerchantName": "Merchant Name",
    "Message.AccessDenied": "Bạn không có quyền truy cập.",
    "Message.ActivateAccountConfirm": "Are you sure you want to activate your account??",
    "Message.BINNoExists": "Bin no exists",
    "Message.BinRangeDisabled": "Có một BIN Range bị disable, vui lòng thử lại",
    "Message.BUCodeExist": "BU Code đã tồn tại!",
    "Message.BUDeclaredService": "BU has been declared service",
    "Message.BUDisabled": "BU vừa Disable, vui lòng thử lại.",
    "Message.ChangePasswordSuccess": "Cập nhật mật khẩu mới thành công",
    "Message.CloneFail": "Clone thất bại. ",
    "Message.CloneSuccess": "Clone thành công",
    "Message.ComebackLater": "Vui lòng thử lại sau",
    "Message.ConfirmGenerateKey": "Do you want to create a new key?",
    "Message.ConsumerKey": "Consumer Key",
    "Message.ConsumerKeyValidUntil": "The Consumer Key Valid Until date is later than the current date.",
    "Message.ContactAdminstrator": "Vui lòng thử lại hoặc liên hệ người quản trị hệ thống",
    "Message.CreatePolicyDraftSuccess": "Tạo Policy Draft thành công",
    "Message.DataNotFound": "Không tìm thấy dữ liệu liên quan.",
    "Message.DisableAccountConfirm": "Are you sure you want to disable your account??",
    "Message.EncryptionCertificateValidUntil": "The Encryption Certificate Valid Until date is later than the current date.",
    "Message.Error403": "Bạn không có quyền truy cập thông tin này",
    "Message.Failed": "Thất bại",
    "Message.FailSendMail": "Gửi mail thất bại",
    "Message.FailureAction": "Cập nhật thất bại. ",
    "Message.GenerateKey": "Generate Key",
    "Message.GenerateKeySuccess": "Generate key success",
    "Message.InsertFail": "Thêm mới thất bại.",
    "Message.InsertSuccess": "Thêm mới thành công",
    "Message.IntegrationAuthenticationKey": "Integration Authentication Key",
    "Message.InternalServerError": "Internal server error.",
    "Message.Invalid": " {$PH} không hợp lệ : {$PH_1}",
    "Message.InvalidAction": "Thao tác không hợp lệ.",
    "Message.InvalidCardBINLength": "Đầu BIN gồm 6 hoặc 8 kí tự",
    "Message.InvalidDate": "Ngày không hợp lệ",
    "Message.InvalidEmail": "Email không hợp lệ.",
    "Message.InvalidMax": "Giá trị tối đa là {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Tối đa {$INTERPOLATION} ký tự",
    "Message.InvalidMin": "Giá trị tối thiểu là {$INTERPOLATION}",
    "Message.InvalidMinLength": "Phải có ít nhất {$INTERPOLATION} ký tự",
    "Message.InvalidMinMaxBINLength": "Độ dài chưa hợp lệ",
    "Message.InvalidMonth": "Tháng không hợp lệ",
    "Message.InvalidPhoneNumber": "Phải nhập đủ 10 ký tự số và ký tự bắt đầu tiên bắt đầu số 0",
    "Message.InvalidRequest": "Invalid Request",
    "Message.InvalidSession": "Phiên đăng nhập đã kết thúc. Vui lòng đăng nhập lại.",
    "Message.InvalidToken": "Token không hợp lệ",
    "Message.KeysInformation": "BU Key",
    "Message.MaxDate": "Ngày được chọn phải nhỏ hơn",
    "Message.MIDNotExist": "",
    "Message.MinDate": "Ngày được chọn phải lớn hơn",
    "Message.NewPasswordMatchOldPassword": "The new password must not be the same as the old password, please check again.",
    "Message.No": "No",
    "Message.NotFoundText": "",
    "Message.NoWhitespace": "",
    "Message.OverSizeFileUpload": "The uploaded file exceeds the allowed size. Please check again.",
    "Message.PasswordPolicyNotMatch": "Mật khẩu mới chưa đúng định dạng quy định, vui lòng nhập lại.",
    "Message.PhoneNumberExist": "Số điện thoại đã tồn tại!",
    "Message.PleaseSelectBU": "Please Select BU before Add Rule",
    "Message.PleaseSelectFileEncryptionCertificateFile": "Please select the Encryption Certificate File.",
    "Message.PleaseSelectFileSigningKeyFile": "Please select the Signing Key File.",
    "Message.PublishPolicyDraftSuccess": "Publish Policy Draft thành công",
    "Message.PublishPolicySuccess": "Publish Policy thành công",
    "Message.ReportCriteriaInformation": "Tiêu chí báo cáo",
    "Message.RequestChangePasswordSuccess": "Hướng dẫn đặt lại mật khẩu đã được gửi tới email của bạn.",
    "Message.required": "Trường bắt buộc nhập",
    "Message.RequiredFail": "Vui lòng nhập các trường bắt buộc",
    "Message.RoleInformation": "Thông tin phân quyền",
    "Message.SelectAtLeastOneAuthenticationMethod": "Select at least one authentication method",
    "Message.SigningKey": "Signing Key",
    "Message.SigningKeyValidUntil": "The Signing Key Valid Until date is later than the current date.",
    "Message.SuccessAction": "Thao tác thành công.",
    "Message.SuccessSendMail": "Gửi mail thành công",
    "Message.TabCardProgram": "Card Program Information",
    "Message.TheBUHasBeenConfiguredWithAllLanguageTypes": "\n          The Business Unit has been configured with all language types, please check again.",
    "Message.TokenExpiration": "Token đã hết hạn",
    "Message.UnderMaintenance": "Hệ thống đang bảo trì",
    "Message.UpdateFail": "Cập nhật thất bại. ",
    "Message.UpdatePasswordSuccess": "Successfully updated the new password",
    "Message.UpdateSuccess": "Cập nhật thành công",
    "Message.ValidConfiguration": "Valid configuration",
    "Message.Yes": "Yes",
    "MessageBody": "Message Body",
    "MessageHeader": "Message Header",
    "Methods": "Methods",
    "ModifyDateTime": "Modify Date Time",
    "Month":"Tháng",
    "Name": "Name",
    "NeedHelp": "Need help?",
    "NewPassword": "New Password",
    "NewValue": "Giá trị hiện tại",
    "Next": "Next",
    "NotApplicable": "Not Applicable",
    "Notice": "Lưu ý: ",
    "OK": "Đồng ý",
    "OldPassword": "Old password",
    "OldValue": "Giá trị cũ",
    "OOB": "OOB",
    "OrderNumber": "STT",
    "OSName": "OSName",
    "Other": "Other",
    "OTPEmail": "OTP Email",
    "OTPEMail": "OTP Email",
    "OTPEOTT": "OTP OTT",
    "OTPOOB": "OTP OOB",
    "OTPOTT": "OTP OTT",
    "OTPSMS": "OTP SMS",
    "Outcome": "Kết quả",
    "Page": "Trang",
    "PANLength": "PAN Length",
    "Password": "Mật khẩu",
    "PasswordChangeNotice": "Mật khẩu gồm 8-64 kí tự, trong đó bao gồm ít nhất 1 số, 1 chữ, 1 kí tự đặc biệt và không được trùng với 5 mật khẩu gần nhất ",
    "PendingChange": "Pending Change",
    "PermissionLog": "Lịch Sử Cập Nhật Quyền",
    "PhoneNumber: Số điện thoại": "Số điện thoại",
    "PhoneNumber:Số điện thoại": "Số điện thoại",
    "PhoneNumber": "Số điện thoại",
    "PleaseSelectCacheType": "Vui lòng chọn loại cache",
    "Policy": "Policy",
    "PolicyAdd": "Policy Add",
    "PolicyCurrent": "Policy hiện tại",
    "PolicyDetail": "Policy Detail",
    "PolicyID": "Policy ID",
    "PolicyInformation": "Thông tin Policy",
    "PolicyLogAction": "Policy Log Action",
    "PolicyLogDetail": "Policy Log Detail",
    "PolicyName": "Tên Policy",
    "PolicyPublishedAndBINRangeConfiguration": "Policy Published & BIN-Range Configuration",
    "PolicySearch": "Policy Search",
    "PolicyUnsaveSelectedRule": "Bạn có chắc muốn rời khỏi? Các Rule bạn thay đổi sẽ không được lưu.",
    "Previous": "Previous",
    "Production": "Production",
    "Profile": "",
    "PublicKey": "Public Key",
    "PublishAndConfig": "Publish và Config",
    "PublishDateTime": "Publish Date Time",
    "PublishPolicyConfirmination": "Bạn có chắc muốn lưu thay đổi?",
    "PublishPolicyDraft": "Publish Policy Draft",
    "PublishPolicyOverrideConfirmation": "Data has been modified before, this action will affect the old data. Are you sure want to continue?",
    "PushProvisioning": "Push Provisioning",
    "PushProvisioningAdd": "Push Provisioning Add",
    "PushProvisioningDetail": "Push Provisioning Detail",
    "PushProvisioningInformation": "Push Provisioning Information",
    "PushProvisioningManagement": "Quản lý Push Provisioning",
    "PushProvisioningRegister": "Đăng ký Push Provisioning",
    "PushProvisioningSearch": "Push Provisioning Search",
    "PwdRuleChar": "Tối thiểu 1 kí tự chữ",
    "PwdRuleLength8And64": "Từ 8 đến 64 ký tự",
    "PwdRuleNum": " Tối thiểu 1 kí tự số",
    "PwdRuleSpec": "Tối thiểu 1 ký tự đặc biệt. Ví dụ: !@#$%^*()”",
    "Quantity":"Số lượng",
    "ReasonCode": "ReasonCode",
    "Rejected": "Rejected ",
    "ReloadAll": "Tải lại tất cả",
    "ReloadCache": "Tải lại cache",
    "Remark: Ghi chú": "Ghi chú",
    "Remark:Ghi chú": "Ghi chú",
    "Remark": "Ghi chú",
    "Remove": "Xóa",
    "RemoveCache": "Xóa cache",
    "RemovePolicyDraftConfirmination": "Bạn có muốn tiếp tục? Thao tác này sẽ xóa bản Draft hiện có của Policy. Mọi thay đổi chưa lưu sẽ bị mất",
    "Report": "Báo cáo",
    "ReportGroup": "Nhóm báo cáo",
    "RequestChangePassword": "Gửi yêu cầu đổi mật khẩu",
    "ReturnHomePage": "Quay lại trang chủ",
    "ReturnToLoginPage": "Quay lại trang chủ",
    "ReviewRule": "Review Rule",
    "Role": " Tên quyền ",
    "RowPerPage": "Số dòng",
    "Rule": "Rule",
    "RuleDescription": "Rule Description",
    "RuleID": "Rule ID",
    "RuleLog": "Rule Log",
    "RuleLogAction": "Rule Log Action",
    "RuleName": "Tên Rule",
    "RuleDetail": "Rule Detail",
    "RuleManagement": "Rule Management",
    "RuleSearch": "Rule Search",
    "RuleStatement": "Rule Statement",
    "Save": "Lưu",
    "Search": "Tìm kiếm",
    "SendMail":"Gửi mail",
    "SendMailConfirmation":"Bạn có chắc gửi mail?",
    "SendPushProvisioningReport": "Báo cáo gửi Push Provisioning",
    "SendRequest": "Gửi yêu cầu",
    "Service": "Dịch vụ",
    "ServiceInformation": "Service Information",
    "ServiceName": "ServiceName",
    "SetPasswordTitle": "Đặt lại mật khẩu",
    "SignIn": "Đăng nhập",
    "SigningKeyAlias": "Signing Key Alias",
    "SigningKeyFile": "Signing Key File",
    "SigningKeyPassword": "Signing Key Password",
    "SignInTitle": "ĐĂNG NHẬP",
    "SignOut": "Đăng Xuất",
    "Start": "Start",
    "Status: Trạng thái": "Trạng thái",
    "Status": "Trạng thái",
    "StatusPublish": "Status Publish",
    "Submit": "Submit",
    "Success": "Success",
    "SuspectedFraud": "Suspected Fraud",
    "System": "Hệ Thống",
    "Tab.AdditionalFields": "Additional Fields",
    "Tab.BillingInfomation": "Billing Infomation",
    "Tab.BusinessUnitInfomation": "Business Unit Infomation",
    "Tab.ErrorMessageInformation": "Error Message Information",
    "Tab.FailWithFeedbackInformation": "Fail With Feedback Information",
    "Tab.LogDetailInfomation": "Log Detail Infomation",
    "Tab.RuleInfomation": "Rule Infomation",
    "Tab.RuleStatememt": "Rule Statememt",
    "Tab.StepUpInformation": " Step-up Information",
    "Tab.SystemInformation": "System Information",
    "Tab.TokenInfomation": "Token details",
    "TemplateEmail": "Template Email",
    "TermOfUse": "Term of use?",
    "TestConfiguration": "Test Configuration",
    "TestMail":"Test mail",
    "Title": "Title",
    "Title[11]": "Title [11]",
    "Title[17]": "Title [17]",
    "Title[4]": "Title [4]",
    "TokenHistory": "Token History",
    "TokenManagement": "Token Management",
    "TokenManagementSearch": "Token Management Search",
    "TokenType": "Token Type",
    "TotalCaches": "Tổng cache",
    "TransactionDetail[12]": "Transaction detail [12]",
    "TransactionDetail[18]": "Transaction detail [18]",
    "TransactionResult": "Transaction Result",
    "TUR": "TUR",
    "UAT": "UAT",
    "Up/Down": "Lên/Xuống",
    "Update:Cập nhật": "Cập nhật",
    "Update": "Cập nhật",
    "UpdateAccountConfirm": "Are you sure you want to update your account information??",
    "UpdatedAt": " Date Time Update",
    "UpdatedBy": "User Update",
    "URL": "URL",
    "UrlLogo": "Url Logo ",
    "URLlogo": "URL logo",
    "URLNeedHelp": "URL Need help",
    "URLTermOfUse": "URL Term of use",
    "User Modify": "User Modify",
    "User": "User",
    "UserAdd": "Thêm mới người dùng",
    "UserID:Số ID của người dùng": "Số ID người dùng",
    "UserID": "Số ID người sử dụng",
    "UserIDModify": "Người cập nhật",
    "UserInformation": "Thông Tin Người Dùng",
    "UserManagement": "Quản lý người dùng",
    "UserModify": "Người cập nhật",
    "UserName": "Tên đăng nhập",
    "UsernameUpdate": "Điều chỉnh User",
    "UserPublish": "User Publish",
    "UserPublished": "User published",
    "UserSearch": "Tra cứu người dùng",
    "ValidateLabel": "Validate Label",
    "ValidateLabel[19]": "Validate Label [19]",
    "ValidateLink": "Validate Link",
    "ValidateLink[20]": "Validate Link [20]",
    "ValidationScreenOTPEmail": "Validation screen OTP Email",
    "ValidationScreenOTPOOB": "Validation screen OTP OOB",
    "ValidationScreenOTPOTT": "Validation screen OTP OTT",
    "ValidationScreenOTPSMS": "Validation screen OTP SMS",
    "Value": "Value",
    "VerificationCode": "Verification Code",
    "VerificationCode[13]": "Verification Code [13]",
    "View": "Xem",
    "ViewDetailMasterCard": " View Detail Master Card ",
    "ViewPolicyCurrent": "Xem Policy gốc",
    "ViewPolicyDraft": "Xem bản Draft",
    "VisibleAdminOnly": "Chỉ hiển thị với người dùng quản trị",
    "AddingRulestoPolicy": "Adding Rules to Policy",
    "General": "General",
    "ACAS-EMV-3DS": "ACAS-EMV 3DS",
    "GlobalScreenConfigurationsSearch": "Global ScreenConfigurations Search",
    "GlobalScreenConfigurationsDetail": "Global ScreenConfigurations Detail",
    "PolicyManagement": "Policy Management",
    "PayByAccount": "Pay By Account",
    "TokenManagementDetail": "Token Management Detail",
    "PushProvisioningDashboard": "Push Provisioning Dashboard",
    "BusinessUnitSearch": "Business Unit Search",
    "BusinessUnitDetail": "Business Unit Detail",
    "CardProgramManagement": "Card Program Management",
    "ListOfReports": "List Of Reports",
    "ZZ": "EndOfTranslations"
  }
}
