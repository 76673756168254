import { ResponseCode, ResponseStatus } from './response-code.type';
import { StorageKey, StorageWrapper } from './storage';


export interface PaginationRequestData {
  Offset: number;
  Limit: number;
}

export interface PaginationResponseData<T> {
  Total: number;
  ItemList: T[];
}

export interface PaginationResponse<T> {
  Total: number;
  ColumnList?: string[],
  ItemList?: T[];
}

export interface PaginationResponseDataItem {
  OrderNumber: number;
}

export interface ImportResponseData<T> {
  InvalidRecords: number;
  ValidRecords: number;
  TotalRecords: number;
  Description: string;
  Records: T[];
}

export interface Errors {
  Errors: string[];
}



export interface ApiRequest<T> {
  RequestDateTime: string;
  RequestID: string;
  Data: T | null;

  Language?: 'vi' | 'en';
}

export interface ApiResponse<T> {
  RequestDateTime: string;
  RequestID: string;

  ResponseCode: ResponseCode;
  Status: ResponseStatus;
  Description?: string;

  Data: T;
  Errors?: Errors;
}

export const buildRequest = <T>(Data: T | null): ApiRequest<T> => ({
  RequestDateTime: new Date().toISOString(),
  RequestID: window.crypto.randomUUID(),
  Data,
  Language: StorageWrapper.get(StorageKey.CURRENT_LANG) === 'en' ? 'en' : 'vi'
})
