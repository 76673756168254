import { Routes } from '@angular/router';
import { Authorize, Unauthenticated } from './auth/auth.guard';
import { ProfileResolver } from './modules/portal/portal.resolve';

export const routes: Routes = [
  { path: 'auth', canMatch: [Unauthenticated], loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'portal', canMatch: [Authorize()], resolve: { profile: ProfileResolver }, loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule) },
  {
    path: 'error',
    children: [
      { path: 'forbidden', loadComponent: () => import('./modules/error/forbidden/forbidden.component').then(mod => mod.ForbiddenComponent) },
      { path: 'not-found', loadComponent: () => import('./modules/error/not-found/not-found.component').then(mod => mod.NotFoundComponent) },
      { path: 'maintenance', loadComponent: () => import('./modules/error/maintenance/maintenance.component').then(mod => mod.MaintenanceComponent) },
      { path: 'internal-server-error', loadComponent: () => import('./modules/error/internal-server-error/internal-server-error.component').then(mod => mod.InternalServerErrorComponent) },
    ]
  },
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  { path: '**', redirectTo: 'error/not-found', pathMatch: 'full' },
];
