/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse, buildRequest } from '@core/api';
import { AESGCMDecrypt, AESGCMEncrypt, SHA512 } from '@core/crypto/aes';
import { ToHex, ToUtf8 } from '@core/crypto/buffer';
import { GenerateKeyPair, GetPublicKey, GetSharedKey } from '@core/crypto/ecdh';
import { Logger } from '@core/log';
import { StorageKey, StorageWrapper } from '@core/storage';
import { environment } from '@environments/environment';
import { Observable, catchError, filter, from, map, mergeMap, of, throwError } from 'rxjs';
import { IAuthService } from './auth';

interface HandshakeResponseData {
  PublicKey: string;
  Salt: string;
  EncryptionKey: string;
  IV: string;
}

type HandshakeData = HandshakeResponseData & { SessionID: string };

const clone = async (request: HttpRequest<any>, handshakeData: HandshakeData) => {
  const isFormData = request.body instanceof FormData;
  const Data = isFormData ? JSON.parse(request.body.get('Data') as string) ?? {} : request.body;
  const body = buildRequest(Data);
  const encryptedBody = ToHex(await AESGCMEncrypt(JSON.stringify(body), handshakeData.EncryptionKey, handshakeData.IV));
  const Signature = await SHA512(encryptedBody + handshakeData.Salt);

  if (isFormData) request.body.set('Data', encryptedBody);

  const update: any = {
    reportProgress: false,
    setHeaders: {
      'X-Session-ID': handshakeData.SessionID,
      Signature,
    },
    body: isFormData ? request.body : encryptedBody,
    responseType: request.responseType == 'json' ? 'text' : request.responseType,
  };

  if (!request.url.includes('/auth/')) {
    const jwt = StorageWrapper.get(StorageKey.ACCESS_TOKEN);
    update.setHeaders.Authorization = `Bearer ${jwt}`;
  }

  Logger.log('Send', request.method, request.url, body);
  return request.clone(update);
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private HandshakeData$!: Observable<HandshakeData>;
  private readonly router: Router = inject(Router);

  constructor(
    @Inject('AuthService') private readonly authService: IAuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let handshake: HandshakeData;
    return this.handshake().pipe(
      mergeMap(async (data) => {
        handshake = data;
        request = await clone(request, handshake);
      }),
      mergeMap(_ => next.handle(request)),
      filter((response: HttpEvent<any>) => response.type != 0),
      mergeMap(async (response: any) => {
        if (response.body && (response.headers as HttpHeaders).get('Content-Type')?.includes('application/json')) {
          response.body = JSON.parse(ToUtf8(await AESGCMDecrypt(response.body, handshake.EncryptionKey, handshake.IV)));
        }
        return response;
      }),
      map((response: any) => {
        Logger.log('Receive', request.method, request.url, response.body);
        if (response.body) {
          const headers = response.headers as HttpHeaders
          const contentType = headers.get('Content-Type')
          if (contentType?.includes('application/json')) {
            if (['403', '404'].includes(response.body?.ResponseCode)) {
              return from(this.router.navigate(['/error/not-found'])).pipe(mergeMap(_ => of(null)));
            }
            if (['500', '505'].includes(response.body?.ResponseCode)) {
              return from(this.router.navigate(['/error/internal-server-error'])).pipe(mergeMap(_ => of(null)));
            }
            if (['501', '502', '503', '504'].includes(response.body?.ResponseCode)) {
              return from(this.router.navigate(['/error/maintenance'])).pipe(mergeMap(_ => of(null)));
            }
          }
        }
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        Logger.log('Error', request.method, request.url, error);
        if (error.status === 401) {
          if (request.url.includes('/account/logout')) {
            return of(null);
          }
          return from(this.authService.sessionExpireNotify(true)).pipe(mergeMap(_ => of(null)));
        }
        if ([404, 403].includes(error.status)) {
          return from(this.router.navigate(['/error/not-found'])).pipe(mergeMap(_ => of(null)));
        }
        if ([500, 505].includes(error.status)) {
          return from(this.router.navigate(['/error/internal-server-error'])).pipe(mergeMap(_ => of(null)));
        }
        if ([501, 502, 503, 504].includes(error.status)) {
          return from(this.router.navigate(['/error/maintenance'])).pipe(mergeMap(_ => of(null)));
        }
        return throwError(() => error)
      })
    )
  }

  handshake(force = false): Observable<HandshakeData> {
    if (!this.HandshakeData$ || force) {
      this.HandshakeData$ = from((async () => {
        const keyPair = await GenerateKeyPair();
        const publicKey = await GetPublicKey(keyPair);
        const data = buildRequest({ PublicKey: publicKey });
        const res = await fetch(`${environment.apiUrl}/auth/handshake`, { method: 'POST', body: JSON.stringify(data) });
        if (!res.ok) throw new Error('Failed to handshake');
        const response = (await res.json()) as ApiResponse<HandshakeResponseData>;
        const sharedKey = await GetSharedKey(keyPair, response.Data.PublicKey);
        const sessionKey = ToHex(await AESGCMDecrypt(response.Data.EncryptionKey, sharedKey));
        const iv = ToHex(await AESGCMDecrypt(response.Data.IV, sharedKey));
        const salt = ToUtf8(await AESGCMDecrypt(response.Data.Salt, sharedKey));
        const result = { SessionID: data.RequestID, EncryptionKey: sessionKey, IV: iv, Salt: salt, PublicKey: '' };
        return result;
      })());
    }
    return this.HandshakeData$;
  }

}
